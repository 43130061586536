import React from 'react';
import { Root } from './style';

import PageHeader from 'components/Common/PageHeader';
import HelpUs from 'components/Common/HelpUs';
import CommonRoadmap from 'components/Common/Roadmap';
import SiteData from 'components/Common/SiteData';
import SiteSection from 'components/Common/SiteSection';

const Roadmap = () => {
  return (
    <Root>
      <PageHeader className="desktop" page="roadmap"/>
      <div className="mobile header">
        <h1>Roadmap</h1>
        <SiteData path="roadmap.title"/>
      </div>
      <CommonRoadmap/>
      <HelpUs/>
      <SiteSection path="roadmap.codeSection" image="imageSection" logo={true}/>
    </Root>
  );
};

export default Roadmap;
