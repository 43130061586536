import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`

  ${vars.desktop.mediaQuery} {
    .page-header h1 {
      font-size: 3rem;
      line-height: 120%;
    }
  }

  ${vars.mobile.mediaQuery} {
    .header {
      text-align: center;
    }

    .header h1 {
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 2rem;
      line-height: 110%;
    }

    .header .raw-html {
      padding: 0 45px;
      color: ${vars.colors.white};
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 145%;
      text-transform: uppercase;
    }

    .header .raw-html span {
      color: ${vars.colors.blue};
    }
  }
`;

export { Root };
